<template>
  <v-slide-x-transition>
    <v-row v-if="items.length && !isLoading">
      <v-col v-for="item in items" cols="12" sm="6" md="6" :key="item.uuid">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="5" class="preview">
                <div>
                  <div v-if="item.product_details" class="preview">
                    <img
                      :src="item.product_details.path_img_thumb"
                      alt=""
                      v-if="item.product_details.path_img_thumb"
                    />
                     <img
                      :src="item.product_details.path_img"
                      alt=""
                      v-if="!item.product_details.path_img_thumb && item.product_details.path_img"
                    />
                    <img
                      v-if="!item.product_details.path_img && !item.product_details.path_img_thumb"
                      :src="require('@/assets/images/product-desc.png')"
                      class="img-thumb"
                    />
                  </div>
                  <div v-if="!item.product_details">
                    <v-icon v-if="item.icon" x-large>{{ `mdi-${item.icon}` }}</v-icon>
                    <v-icon v-else x-large>mdi-table-merge-cells</v-icon>
                  </div>
                </div>
              </v-col>
              <v-col cols="7">
                <v-simple-table class="my-table supply" dense>
                  <tbody>
                    <tr>
                      <td>{{ $t("table.name") }}:</td>
                      <td
                        :class="{
                          copyTxt: item.product_details ? item.product_details.name : item.name
                        }"
                        @click="
                          copyToClipboard(
                            item.product_details ? item.product_details.name : item.name
                          )
                        "
                      >
                        {{ item.product_details ? item.product_details.name : item.name || "--" }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("table.description") }}:</td>
                      <td
                        class="text-truncate"
                        :class="{
                          copyTxt: item.product_details
                            ? item.product_details.description
                            : item.description
                        }"
                        @click="
                          copyToClipboard(
                            item.product_details
                              ? item.product_details.description
                              : item.description
                          )
                        "
                      >
                        {{
                          item.product_details
                            ? item.product_details.description
                            : item.description || "--"
                        }}
                      </td>
                    </tr>
                    <tr v-if="item.product_details">
                      <td>{{ $t("table.bar_code") }}::</td>
                      <td class="copyTxt" @click="copyToClipboard(item.product_details.bar_code)">
                        {{ item.product_details.bar_code || "--" }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("table.ns_code") }}::</td>
                      <td
                        :class="{
                          copyTxt: item.ns_code
                        }"
                        @click="copyToClipboard(item.ns_code)"
                      >
                        {{ item.ns_code || "--" }}
                      </td>
                    </tr>
                    <tr v-if="item.product_details">
                      <td>{{ $t("table.amount") }}::</td>
                      <td>{{ item.count || 0 }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!items.length && chosenElements" justify="center">
      <v-col cols="10" md="6">
        <v-card class="text-center">
          <v-card-text>{{$t('table.noData')}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-slide-x-transition>
</template>

<script>
import EventBus from "../../../events/EventBus";

export default {
  methods: {
    copyToClipboard(data) {
      if (!data) return;
      EventBus.$emit("on-clipboard", data);
    }
  },
  props: {
    items: { required: true },
    chosenElements: { required: true },
    isLoading: { required: true }
  }
};
</script>

<style lang="scss" scoped>
.preview {
  max-width: 100%;
  height: 200px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    &.img-thumb {
      object-fit: cover !important;
    }
  }
}
</style>
