<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    @click:outside="showConfirmDialog"
    :persistent="isChanged"
     content-class="cross__dialog"
     scrollable
     :transition="$dialogTransition"

  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? 'auto' : ''">
            <div class="cross__inner">
      <VBtn fab small  elevation="0" @click="showConfirmDialog">
        <VIcon>mdi-close</VIcon>
      </VBtn>
      </div>
      <VCardTitle>
        <span class="headline"  :class="{ 'text-body-1': $vuetify.breakpoint.xs }"> {{ $t('sectors.update_sector') }}</span>

      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow>
              <VCol cols="12">
                <VTextField
                  outlined dense
                  :label="$t('form.name')"
                  v-model="inputSector.name"
                  required
                  :error-messages="titleErrors"
                  hide-details="auto"
                  color="success"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                ></VTextField>
              </VCol>
              <VCol cols="12">
                <v-autocomplete
                  :label="$t('form.storage')"
                  :items="depots"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  :value="inputSector.uuid_deport"
                  v-model="inputSector.uuid_deport"
                  outlined
                  dense
                  hide-details="auto"
                  small-chips
                  color="success"
                  item-color="success"
                  @keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
              <VCol cols="12" class="py-0">
                <VTextarea
                  outlined dense
                  :label="$t('form.description')"
                  required
                  v-model="inputSector.description"
                  no-resize
                  counter
                  maxlength="255"
                  rows="2"
                  color="success"
                  @keypress="validationForDepartment($event)"
                  @paste="validationForDepartment($event)"
                ></VTextarea>
              </VCol>
            </VRow>
            <div v-if="cells.length">
              <h3 class="mb-1" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{$t('sectors.available_cells')}}:</h3>
              <VCard
                elevation="2"
                class="pa-2 mb-1"
                v-for="cell in cells"
                :key="cell.uuid"
              >
                <b>{{ cell.name }}</b><br>
                {{ cell.description }}<br>
                <small>{{ cell.time_created | noData }}</small>
              </VCard>
            </div>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container">
          <VCol col="6">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              dark
              block
              @click="visibility = false"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol col="6">
            <VBtn
              block
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onUpdate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.update') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
     <ConfirmLeftDialog
    :visible="visibleConfirm"
    @close="visibleConfirm = false"
    @delete="onClose"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import depotService from '../../services/request/depot/depotService';
import EventBus from '../../events/EventBus';
import {
  validationForDepartment,
  validationForTitle,
  validationForWarehouses
} from "@/mixins/helpers";

export default {
  name: 'UpdateSectorDialog',
  mixins: [language, notifications, validationMixin],
  validations: {
    inputSector: {
      name: { required },
    },
  },
  components:{
    ConfirmLeftDialog
  },
  props: {
    visible: {
      required: true,
    },
    sector: {
      required: true,
    },
  },
    watch: {
    inputSector: {
      deep: true,
      handler(e) {
        // orderDialog(e);
        this.isChanged = !_.isEqual(this.sector, this.inputSector);
      }
    },
    role: "updateRoleInfo"
  },
  data: () => ({
    loading: false,
    visibleConfirm:false,
    isChanged:false,
    cells: [],
    depots: [],
    inputSector: {},
  }),
  methods: {
    validationForDepartment,
    validationForWarehouses,
    validationForTitle,
     showConfirmDialog(){
      if(this.isChanged){
      this.visibleConfirm = true
      }else{
        this.onClose();
      }
    },
       onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('uuid', this.inputSector.uuid);
          const changedData = this.$getChangedData(this.inputSector, this.sector);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          await depotService.updateSector(formData);
          this.loading = false;
          this.$v.$reset();
          this.setSuccessNotification(this.$t('sectors.sector_was_updated_successfully'));
          this.visibility = false;
          EventBus.$emit('sector-updated');
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
  },
  async mounted() {
    this.inputSector = { ...this.sector };
    this.depots = await depotService.getDepotsList();
    this.cells = await depotService.getCellsList({
      uuid_sector: this.sector.uuid,
    });
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.inputSector.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputSector.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
  },
};
</script>

<style scoped>

</style>
