<template>
  <nested-component />
</template>

<script>
import NestedComponent from "../../components/nested-data/NestedComponent.vue";

export default {
  components: { NestedComponent }
};
</script>

<style></style>
