<template>
  <div>
    <div style="height:3px">
      <v-progress-linear
        indeterminate
        color="success"
        height="3"
        v-if="isLoading && dataView === 1"
      ></v-progress-linear>
    </div>
    <v-slide-x-transition>
      <v-row align="center" justify="space-between" no-gutters class="mx-5">
        <v-col cols="12" xl="3" md="3" sm="12">
          <v-text-field
            v-if="items.length"
            prepend-inner-icon="mdi-magnify"
            dense
            :label="$t('form.search')"
            v-model="searchString"
            hide-details="true"
            clearable
            ref="searchField"
            solo
            :loading="isLoadingSearch"
          />
        </v-col>
        <v-col cols="auto" v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn-toggle v-model="dataView" @change="onCahngeListView" mandatory dense>
            <v-btn>
              <v-icon color="#757575">mdi-menu</v-icon>
            </v-btn>
            <v-btn>
              <card-list-icon />
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-slide-x-transition>
    <v-card-text>
      <v-slide-x-transition v-if="dataView === 1" mode="out-in">
        <card-view-data :items="onSearch" :chosenElements="chosenElements" :isLoading="isLoading" />
      </v-slide-x-transition>
      <v-slide-x-transition v-if="dataView === 0" mode="out-in">
        <table-view-data
          :items="onSearch"
          :chosenElements="chosenElements"
          :isLoading="isLoading"
        />
      </v-slide-x-transition>
      <v-row v-if="!chosenElements" justify="center">
        <v-col cols="10" md="6">
          <v-card class="text-center">
            <v-card-text>{{ $t("nestedData.choose_smt") }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import _ from 'lodash';
import depotService from "../../services/request/depot/depotService";
import notifications from "../../mixins/notifications";
import EventBus from "../../events/EventBus";
import CardViewData from "./view-type/CardViewData.vue";
import TableViewData from "./view-type/TableViewData.vue";

const defaultUUID = "00000000-0000-0000-0000-000000000000";

export default {
  components: { CardViewData, TableViewData },
  mounted() {
    const storageView = localStorage.getItem("nsBrowser_view")
     

    if (storageView) {
      this.dataView = JSON.parse(storageView);
    } else {
      this.dataView = 1;
    }

    EventBus.$on("on-clipboard", data => {
      this.copyToClipboard(data);
    });
  },
  data: () => ({
    items: [],
    isLoading: false,
    searchString: "",
    isLoadingSearch: false,
    dataView: ""
  }),

  methods: {
    onCahngeListView() {
      localStorage.setItem("nsBrowser_view", this.dataView);
    },
    copyToClipboard: _.debounce(function(e) {
      try {
        navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },10)
  },
  computed: {
    async getItems() {
      const result = await depotService.getAllItems({
        uuid_cell: this.chosenElements.id,
        groupby: "option_details.name"
      });
      return result;
    },
    onSearch() {
      return !this.searchString
        ? this.items
        : [...this.items].filter(item => {
            const obj = Object.prototype.hasOwnProperty.call(item, "name")
              ? item.name
              : item.product_details.name;
            return obj.toLowerCase().includes(this.searchString.toLowerCase());
          });
    }
  },
  props: {
    chosenElements: {
      require: true
    },
    cells: {
      require: true
    },
    sectors: {
      require: true
    }
  },
  watch: {
    chosenElements: {
      deep: true,
      async handler(chosenElement) {
        //  
        this.items = [];
        if (!chosenElement) return;
        this.isLoading = true;

        // sectors with cells
        if (chosenElement.children && chosenElement.type === "sector") {
           
          this.items = this.cells.filter(
            cell => cell.uuid_sector === chosenElement.id && cell.uuid_parent === defaultUUID
          );
        }
        // sectors with cells
        if (!chosenElement?.children?.length && chosenElement.type === "sector") {
          this.items = Array(chosenElement);
        }
        // cell with cells and goods
        if (chosenElement?.children?.length && chosenElement.type === "cell") {
          const goods = await this.getItems;
          const cells = [];

          chosenElement.children.forEach(item => {
            cells.push(...this.cells.filter(cell => cell.uuid === item.id));
          });

          this.items = cells.concat(goods);
        }
        // cell with goods
        if (!chosenElement?.children?.length && chosenElement.type === "cell") {
          this.items = await this.getItems;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
      }
    }
  },
  mixins: [notifications]
};
</script>

<style scoped lang="scss"></style>
