<template>
  <div>
    <div v-if="items">
      <v-treeview
        :items="items"
        activatable
        :item-key="'id'"
        color="success"
        return-object
        hoverable
        transition
        :active.sync="active"
        :open.sync="open"
        :search="searchQuery"
        selected-color="success"
      >
        <template v-slot:prepend="{ item }">
          <v-icon dense>
            {{ `mdi-${item.icon}` }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <v-hover v-slot="{ hover }">
            <div class="d-flex justify-content-space-between align-center" no-gutters>
              <span class="text-truncate">{{ item.name }}</span>
              <v-slide-x-reverse-transition>
                <v-btn
                  class="cursor-pointer"
                  icon
                  @click.stop="onClickDots(item)"
                  v-if="
                    (hover && permissions.owner && item.type === 'sector') ||
                      (hover && item.type === 'cell')
                  "
                  small
                >
                  <v-icon dense>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </v-slide-x-reverse-transition>
            </div>
          </v-hover>
        </template>
      </v-treeview>
    </div>
    <div v-else>
      <VCol>
        {{$t('table.noData')}}
      </VCol>
    </div>
    <update-sector-dialog
      v-if="updateSectorDialogVisible"
      :visible="updateSectorDialogVisible"
      :sector="chosenItemUpdate.sector"
      :depots="deports"
      @close="updateSectorDialogVisible = false"
    />
    <edit-cell-dialog
      :visible="updateCellDialogVisible"
      @close="
        updateCellDialogVisible = false;
        cellItems = [];
      "
      :cell="chosenItemUpdate.cell"
      :sectors="sectors"
      :items="cellItems"
      v-if="updateCellDialogVisible"
    />
  </div>
</template>

<script>
import EventBus from "../../events/EventBus";
import user from "../../mixins/user";
import UpdateSectorDialog from "../sectors/UpdateSectorDialog.vue";
import EditCellDialog from "../cells/EditCellDialog.vue";
import depotService from "../../services/request/depot/depotService";

export default {
  components: { UpdateSectorDialog, EditCellDialog },
  data: () => ({
    chosenItem: [],
    updateSectorDialogVisible: false,
    updateCellDialogVisible: false,
    chosenItemUpdate: {},
    cellItems: [],
    initiallyOpen: [],
    tree: []
  }),
  mounted() {
    EventBus.$on("sector-updated", async () => {
      EventBus.$emit("tree-updated");
    });
    EventBus.$on("cell-updated", async () => {
      EventBus.$emit("tree-updated");
    });
  },
  methods: {
    async onClickDots(item) {
      if (item.type === "sector") {
        this.updateSectorDialogVisible = true;
        this.chosenItemUpdate.sector = this.sectors.find(cell => cell.uuid === item.id);
      }
      if (item.type === "cell") {
        this.updateCellDialogVisible = true;
        this.chosenItemUpdate.cell = this.cells.find(cell => cell.uuid === item.id);
        this.cellItems = await this.getItems(item);
      }
    },
    onClickElem(elem) {
      EventBus.$emit("choose-elem", elem);
    },
    async getItems(item) {
      const result = await depotService.getAllItems({
        uuid_cell: item.id,
        groupby: "option_details.name"
      });
      return result;
    },
    getChildrenItem(item) {
       
    },
    getParentId(id) {
      return this.items.filter(item => item.id_parent === id);
    },
    searchOpenItem(arr) {

      if (arr.length) {
        arr.forEach(item => {
          if (item.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
            const idParent = { id: item.id_parent,type:'parent',name:item.name };
            const id = { id: item.id,name:item.name,type:'child' };
            this.initiallyOpen.push(idParent, id);
          }
          if (item.children.length) {
            this.searchOpenItem(item.children);
          }
        });
      }
  
    }
  },
  computed: {
    open: {
      get() {
        if (this.searchQuery) {
          this.searchOpenItem(this.items);
          return this.initiallyOpen;
        }else{
          this.initiallyOpen = []
        }
        return [];
      },
      set(elem) {
        //  
      }
    },

    active: {
      get() {
        return [];
      },
      set(elem) {
        EventBus.$emit("choose-elem", elem);
      }
    }
  },

  watch: {
    items: {
      deep: true,
      handler(e) {
         
      }
    }
  },

  props: {
    items: {
      require: true
    },

    searchQuery: {
      require: true,
      default: ""
    },
    sectors: {
      require: false
    },
    cells: {
      require: true
    },
    deports: {
      require: false
    }
  },
  mixins: [user]
};
</script>

<style lang="scss">
.v-application--is-ltr .v-treeview-node__label {
  cursor: pointer;
}
</style>
